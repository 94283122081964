import "./css/App.css";
import React from 'react';
import { AdminProvider } from './contexts/AdminContext';
import { PageLayout } from "./components/common/PageLayout"
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { PerformAuthAction } from "./components/common/SignInOutButton";
import { Home } from "./components/Home";
import { Switches } from './components/common/Switches';
import { Card } from 'react-bootstrap';

function App() { 
    return (
        <>
            <PageLayout></PageLayout>
            <AuthenticatedTemplate>
                <AdminProvider>
                <Switches>
                    <Home />
                </Switches>
                </AdminProvider>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <Card className="auth-card shadow">
                    <Card.Img src="/CMTopologyToolLogo.png" alt="Microsoft Logo" />
                    <center>
                        <br/>
                    <Card.Title>Change Management Topology Tool</Card.Title>
                    </center>
                    <Card.Body>
                        Please log in with your <b>@microsoft</b> account.
                        <br/>
                        <center class="sign-in-btn">
                            <PerformAuthAction  actionType="login" />
                         </center>

                    </Card.Body>
                </Card>
            </UnauthenticatedTemplate>
        </>
    );
};

export default App;

