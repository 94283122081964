import React, { useEffect, useState } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import Constants from '../Constants';

export const DeviceListEditor = ({ isMopRequired, item, handleInputChange }) => {
    const [deviceList, setDeviceList] = useState("");
    const [spansList, setSpansList] = useState("");

    useEffect(() => {
        setDeviceList(item.devices?.join(', '));
        setSpansList(item.spans?.join(', '));
    }, [isMopRequired, item.devices, item.spans]);

    const validateAndSetDevices = (e) => {
        if (!isMopRequired) {
            const inputValue = e.target.value;
            processAndSetDevices(inputValue);
        }
    };

    const validateAndSetSpans = (e) => {
        if (!isMopRequired) {
            const inputValue = e.target.value;
            processAndSetSpans(inputValue);
        }
    };

    const processAndSetDevices = (inputValue) => {
        let devices = inputValue.split(/[\n, ]+/).map(device => device.trim()).filter(device => device !== '');
        setDeviceList(inputValue); // Update the textarea with the user's input
        handleInputChange({ target: { name: "devices", value: devices } });
    };

    const processAndSetSpans = (inputValue) => {
        let spans = inputValue.split(/[\n, ]+/).map(span => span.trim()).filter(span => span !== '');
        setSpansList(inputValue); // Update the textarea with the user's input
        handleInputChange({ target: { name: "spans", value: spans } });
    };

    const formatSpanListOnBlur = (e) => {
        if (!isMopRequired) {
            let spans = e.target.value.split(/[\n, ]+/).map(span => span.trim()).filter(span => span !== '');
            setSpansList(spans.join(', '));
        }
    }

    const formatDeviceListOnBlur = (e) => {
        if (!isMopRequired) {
            // On blur, format the device list to be comma-separated
            let devices = e.target.value.split(/[\n, ]+/).map(device => device.trim()).filter(device => device !== '');
            setDeviceList(devices.join(', ')); // Update the textarea with the formatted list
        }
    };

    return (
        <Row>
            <Col md={item?.spans && item.spans.length > 0 ? 6 : 12}>
                <Form.Group className="mb-3">
                    <Form.Label>Devices {isMopRequired && "in MOP"}<span className="text-danger">*</span></Form.Label>
                    <p className="description-text">Separate with a comma or a new line</p>
                    <Form.Control
                        name="devices"
                        as="textarea"
                        className="cr-textarea"
                        value={deviceList}
                        onChange={validateAndSetDevices}
                        onBlur={formatDeviceListOnBlur}
                        disabled={isMopRequired || Constants.FiberMaintenanceTypes.includes(item.maintenanceType) || [Constants.ApprovedStatus, Constants.CancelledStatus].includes(item.status)}
                        required={!isMopRequired}
                    />
                </Form.Group>
            </Col>
            {item?.spans && item.spans.length > 0 &&
                <Col md={6}>
                    <Form.Group className="mb-3">
                        <Form.Label>Spans <span className="text-danger">*</span></Form.Label>
                        <p className="description-text">Separate with a comma or a new line</p>
                        <Form.Control
                            name="spans"
                            as="textarea"
                            className="cr-textarea"
                            value={spansList}
                            onChange={validateAndSetSpans}
                            onBlur={formatSpanListOnBlur}
                            disabled={[Constants.ApprovedStatus, Constants.CancelledStatus, Constants.InReviewStatus].includes(item.status)}
                            required
                        />
                    </Form.Group>
                </Col>}
        </Row>
    );
};