import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from 'react-router-dom'
import App from "./App";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider} from "@azure/msal-react";
import { msalConfig } from "./config/authConfig";
import "bootstrap/dist/css/bootstrap.min.css";
import "./css/index.css";

const pca = new PublicClientApplication(msalConfig);

export default function Start() {
    return (
        <React.StrictMode>
                <MsalProvider instance={pca}>
                    <App />
                </MsalProvider>
        </React.StrictMode>
    );
}

ReactDOM.render(
    <Start/>,
    document.getElementById("root")
);
