import React, { useState, useEffect } from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';
import { extractName, extractPrettyTime } from '../common/Formatting';
import Constants from '../Constants';

export const CommentSection = ({ crId, user, bearer }) => {
    const [comments, setComments] = useState([]);
    const [newComment, setNewComment] = useState('');
    const [commentStatus, setCommentStatus] = useState('');
    const [getGetCommentError, setError] = useState(null);
    const [buttonLoading, setButtonLoading] = useState(false);

    useEffect(() => {
        fetchComments();
    }, [crId]);

    const fetchComments = async () => {
        const options = {
            method: 'GET',
            headers: {
                'Authorization': bearer,
            }
        };
        try {
            const response = await fetch(
                process.env.REACT_APP_API_CLIENT_URL + `ChangeManagement/GetComments/?changeId=${crId}`, options);
            const resultJson = await response.json();
            if (resultJson[Constants.responseCode] !== Constants.SuccessAPIResponseCode) {
                throw new Error(resultJson[Constants.responseMessage])
            }
            await setComments(resultJson.data);
        } catch (error) {
            console.error('Error fetching comments:', error);
            setError('Failed to load comments', error);
        }
    };

    const handleInputChange = (event) => {
        event.preventDefault();
        const { value } = event.target;
        setNewComment(value);
    };

    const handleSubmit = async (event) => {
        setButtonLoading(true);
        try {
            event.preventDefault();
            event.stopPropagation();
            if (newComment === '') {
                setButtonLoading(false);
                return;
            }
            setCommentStatus('');
            const url = process.env.REACT_APP_API_CLIENT_URL + 'ChangeManagement/AddComment';
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': bearer,
                },
                body: JSON.stringify({
                    ChangeId: crId,
                    Email: user,
                    CommentText: newComment,
                }),
            });
            const resultJson = await response.json();
            if (resultJson[Constants.responseCode] !== Constants.SuccessAPIResponseCode) {
                throw new Error(resultJson[Constants.responseMessage])
            }
            await setComments([...comments, resultJson.data]);
            setNewComment('');
        } catch (error) {
            console.error('Error posting comment:', error);
            setCommentStatus('Error posting comment');
        }
        setButtonLoading(false);
    };

    return (
        <>
            <Form>
                <Row>
                    <Col className="col-9">
                        <Form.Control
                            name="newComment"
                            value={newComment}
                            onChange={handleInputChange}
                            as="textarea"
                            className="cr-textarea"
                            placeholder="Notifications will NOT be generated if you @ people. Please use the teams channel to get attention."
                        />
                    </Col>
                    <Col className="col-3">
                        <Button type="button" name="postComment" onClick={handleSubmit}>
                            {buttonLoading ? "Loading..." : "Post"}
                        </Button>
                        <div>
                            <br />
                            <a href="https://teams.microsoft.com/l/channel/19%3Ac2b71fcdfe8844a9a7c3798ddebfea78%40thread.tacv2/DCS-Phynet%20change%20mgmt?groupId=6ba6cba9-8e56-4473-b0ba-d4b284405107&tenantId=72f988bf-86f1-41af-91ab-2d7cd011db47" target="_blank" rel="noopener noreferrer">Teams channel</a>
                        </div>
                    </Col>
                </Row>
            </Form>
            {commentStatus && <p>{commentStatus}</p>}
            {getGetCommentError ? (<p>{getGetCommentError}</p>) : (
                <div name="comments">
                    {comments?.map((comment) => (
                        <Form.Group key={comment.CreatedTimestamp}>
                            <Form.Label>{extractName(comment.Email)} at {extractPrettyTime(comment.CreatedTimestamp)}</Form.Label>
                            <Form.Control
                                as="textarea"
                                value={comment.CommentText}
                                disabled
                            />
                        </Form.Group>
                    ))}
                </div>
            )}
        </>
    );
};