import React, { useState, useEffect } from 'react';
import { Box, Typography, Paper } from '@mui/material';

// Function to format the date as "UTC 3/1/2001, 12:00:00 AM (UTC)"
const formatUtcDate = (date) => {
    const options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: true,
        timeZone: 'UTC'
    };
    return `${new Intl.DateTimeFormat('en-US', options).format(date)} (UTC)`;
};

export const UtcClock = () => {
    const [utcTime, setUtcTime] = useState(formatUtcDate(new Date()));

    useEffect(() => {
        const intervalId = setInterval(() => {
            setUtcTime(formatUtcDate(new Date()));
        }, 1000);

        return () => clearInterval(intervalId);
    }, []);

    return (
        <Paper elevation={3} sx={{ padding: 2, display: 'flex', alignItems: 'center', margin: 'auto', color:'white', background:'transparent' }}>
            <Box>
                <Typography variant="body1" component="p">
                    {utcTime}
                </Typography>
            </Box>
        </Paper>
    );
};