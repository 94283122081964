import React, { useEffect, useState } from "react";
import Constants from '../Constants';
import { Table, Dropdown } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import { useMsal } from "@azure/msal-react"
import { Application } from "../../config/authConfig";
import { extractName, extractPrettyTime } from "../common/Formatting";
import DiffView from './DiffView';
import '../../css/change-record.css';

export const ChangeRecordDiff = () => {
    const { instance, accounts } = useMsal();
    const [token, setToken] = useState(null);
    const [changeHistory, setChangeHistory] = useState([]);
    const [changeHistoryFetchError, setChangeHistoryFetchError] = useState(null);
    const [selectedIteration, setSelectedIteration] = useState(null);
    const { changeId } = useParams();
    const [splitView, setSplitView] = useState(true);


    const request = {
        ...Application.clientApi,
        account: accounts[0]
    }

    instance.acquireTokenSilent(request).then((response) => {
        setToken(response.accessToken);
    }).catch((e) => {
        console.log(e, "exception");
    })

    const bearer = 'Bearer ' + token;

    // Load iterations and get iteration count
    useEffect(() => {
        async function fetchData() {
            try {
                const options = {
                    method: 'GET',
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": bearer,
                    }
                };
                var url = process.env.REACT_APP_API_CLIENT_URL + 'ChangeManagement/GetChangeHistory/?changeId='+changeId;
                const response = await fetch(url, options);
                const resultJson = await response.json();
                if (resultJson[Constants.responseCode] !== Constants.SuccessAPIResponseCode) {
                    throw new Error(resultJson[Constants.responseMessage])
                }
                await setChangeHistory(resultJson.data);
                setSelectedIteration(resultJson.data.length);
            }
            catch (error) {
                await setChangeHistoryFetchError(error);
            }
        }
        fetchData();
    }, [token]);

    const handleSelect = (eventKey) => {
        setSplitView(eventKey === "side-by-side");
    };
    return (
        <>
        <div className="d-flex justify-content-end">
        <Dropdown onSelect={handleSelect} className="diff-dropdown">
            <Dropdown.Toggle variant = "outline">
                {splitView ? "Side by Side" : "Inline"}
            </Dropdown.Toggle>
            <Dropdown.Menu>
                <Dropdown.Item eventKey="side-by-side">Side by Side</Dropdown.Item>
                <Dropdown.Item eventKey="inline">Inline</Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    </div>
        <Table>
            <tbody>
                {changeHistory.sort((a, b) => b.iterationId - a.iterationId).map((iteration, index, array) => {
                    const previousIteration = array[index + 1];
                    const actionText = previousIteration ? 'Modified by' : 'Created by';

                    return (
                        <tr key={iteration.iterationId}>
                            <td>{`${actionText} ${extractName(iteration.lastUpdatedUser)} at ${extractPrettyTime(iteration.lastUpdatedTimestamp)}`}</td>
                            {previousIteration && 
                            <DiffView 
                            oldValue={previousIteration}
                            newValue={iteration}
                            splitView={splitView} />}
                        </tr>
                    );
                })}
            </tbody>
        </Table>
        </>
    );
}
