import Constants from "../Constants";
export function handleHttpError(status) {
  switch (status) {
    case 500:
      throw new Error("Internal Service Error");
    case 502:
      throw new Error("Bad Gateway");
    case 503:
      throw new Error("Service Unavailable");
    case 504:
      throw new Error("Gateway Timeout");
    default:
      throw new Error("An unexpected error occurred");
  }
}

export function handleApiError(resultJson) {
  if (resultJson[Constants.responseCode].startsWith("CVB")) {
    throw new Error(resultJson[Constants.responseMessage]);
  } else {
    throw new Error(
      "There was an error validating your change. Please try again."
    );
  }
}

export function isKustoError(resultJson) {
  return (
    resultJson.data.validationResult?.rejectedChecks.length === 1 &&
    resultJson.data.validationResult?.rejectedChecks[0][
      Constants.responseCode
    ] === Constants.KustoErrorAPIResponseCode
  );
}

export function getMaxDate() {
  const date = new Date();
  date.setUTCDate(date.getUTCDate() + 7);
  return date.toISOString().slice(0, 16);
}
