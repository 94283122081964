import React, { useState }  from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Link, Tooltip } from '@mui/material';
import { extractPrettyTime } from '../common/Formatting';
import { CheckCircle, Error } from '@mui/icons-material';
import Constants from '../Constants';

export const ChangeRecordTable = ({ data, loading, handleShowEditModal}) => {

    const columns = [
        ...Constants.changeRecordHomePageFields.map((item) => ({
            field: item.key,
            headerName: item.name,
            width: item.width,
            hide: item.hide,
            renderCell: (params) => {
                if (item.key.toLowerCase() === 'changeid') {
                    return (
                        <Link onClick={() => handleShowEditModal(params.row)}>
                            {params.value}
                        </Link>
                    );
                }
                else if (item.key.toLowerCase() === 'isncvsvalidated') {
                    if (params.value === "True") {
                        return (
                            <Tooltip title="true">
                                <CheckCircle style={{ color: 'green' }} />
                            </Tooltip>
                        );
                    } else if (params.value === "False") {
                        return (
                            <Tooltip title="false">
                                <Error style={{ color: 'red' }} />
                            </Tooltip>
                        );
                    }
                }
                else if (item.type === 'datetime') {
                    return extractPrettyTime(params.value, true);
                }
                 else {
                    return params.value;
                }
            },
        })),
    ];


    return (
        <div className='bootstrap-style-table'>
            <DataGrid
                autoHeight
                sx={{fontSize: 16}}
                rows={data}
                getRowId={(row) => row.changeId}
                columns={columns}
                pageSizeOptions={[10, 25, 50,100]}
                loading={loading}
                slots={
                    {
                        noRowsOverlay: () => <center>
                             <br/> <br/>
                             No Change Records Found
                             </center>
                    }
                }
            />
        </div>
    );
};