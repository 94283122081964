import 'bootstrap/dist/css/bootstrap.min.css'
import '../../css/App.css';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Form, Table, Container, Row, Col, InputGroup, } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import { useMsal } from "@azure/msal-react"
import { Application } from "../../config/authConfig";
import Constants from '../Constants';
import { AddBlockListItem } from "./AddBlockListItem";
import { ViewBlockListItem } from "./ViewBlockListItem";
import { useAdmin } from '../../contexts/AdminContext';

export const BlockListHomePage = () => {
    const { isAdmin } = useAdmin();
    const [data, setData] = useState([]);
    const [error, setError] = useState(null)
    const [token, setToken] = useState();
    const { instance, accounts } = useMsal();
    const [filterType, setSearchFilterType] = useState('');
    const [searchText, setSearchFilterText] = useState("string");
    const [showAddModal, setShowAddModal] = useState(false);
    const [showViewModal, setShowViewModal] = useState(false);
    const [editData, setEditData] = useState(null);
    const [refreshTable, setRefreshTable] = useState(false);
    const [buttonLoading, setButtonLoading] = useState(false);
    const navigate = useNavigate();

    const request = {
        ...Application.clientApi,
        account: accounts[0]
    }

    instance.acquireTokenSilent(request).then((response) => {
        setToken(response.accessToken);
    }).catch((e) => {
        console.log(e, "exception");
    })

    const bearer = 'Bearer ' + token;

    const options = {
        method: 'GET',
        headers: {
            'Authorization': bearer,
        }
    };

    const getBlockListData = (api) => {

        fetch(api, options)
            .then(res => res.json())
            .then((result) => {
                if (result[Constants.responseCode] === Constants.SuccessAPIResponseCode) {
                    setData(result["data"])
                    setError(null);
                }
                else {
                    setError(result["responseMessage"]);
                }
            },
                (error) => {
                    setError(error);
                })
            .catch((e) => console.log(e, "apiErro"))
            .finally(() => {
                setButtonLoading(false);
            });
    }

    const handleSearchSubmit = (event) => {

        setButtonLoading(true);
        event.preventDefault();

        var api = process.env.REACT_APP_API_CLIENT_URL + 'BlockList/GetBlocklists/?filterType=' + filterType + '&filterString=' + (searchText == '' ? "empty" : searchText);

        getBlockListData(api)

    };

    useEffect(() => {
        if (token != null) {
            getBlockListData(process.env.REACT_APP_API_CLIENT_URL + 'BlockList/GetAllBlockLists')
        }
    }, [token, setShowViewModal, setShowAddModal, refreshTable]);

    return (
        <Container fluid="md">
            <h3>Phynet Block List</h3>
            <Row>
                <Col>
                    <Form onSubmit={handleSearchSubmit} class="square border border-primary">
                        <Form.Group className="input-group">
                            <div className="input-group">
                                <InputGroup className="mb-3">
                                    <Form.Select required aria-label="Search by" class="sm" onChange={event => setSearchFilterType(event.target.value)}>
                                        <option value="all">Search By</option>
                                        <option value="Region">Region</option>
                                        <option value="Datacenter">Datacenter</option>
                                        <option value="Cluster">Cluster</option>
                                        <option value="DeviceName">Device Name</option>
                                        <option value="HardwareSku">Hardware Sku</option>
                                    </Form.Select>
                                    <Form.Control name="searchText" aria-label="Search" onChange={event => setSearchFilterText(event.target.value)} />
                                    <span class="input-group-btn input-space">
                                        <Button
                                            className="InputButton"
                                            type="submit"
                                            disabled={buttonLoading}>
                                            {buttonLoading ? "Loading..." : "Search"}
                                        </Button>
                                    </span>
                                </InputGroup>

                            </div>
                        </Form.Group>
                    </Form>
                </Col>
                <Col>
                    {isAdmin &&
                            <Button
                            className="InputButton"
                            type="submit"
                            onClick={() => {
                                setShowAddModal(true);
                            }}>
                                Add
                        </Button>
                        }
                    {showAddModal &&
                        <AddBlockListItem
                            show={showAddModal}
                            onhide={() => setShowAddModal(false)}
                        />}
                </Col>
            </Row>
            <div>
                <Table striped bordered hover responsive>
                    <thead>
                        <tr>
                            {
                                Constants.blockListFields.map((item) => (
                                    !item.disabled && <th className="table-header">{item.name}</th>
                                ))
                            }
                            <th className="table-header">
                                <Button variant="link" onClick={() => { setRefreshTable(!refreshTable) }}>Refresh&#8634;</Button>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {(error === null) &&
                            (data.length !== 0) &&
                            data.map((item) => (
                                <tr>
                                    <td>{item.startTime}</td>
                                    <td>{item.endTime}</td>
                                    <td>{item.deviceRegex}</td>
                                    <td>{item.ngsDeviceType}</td>
                                    <td>{item.hardwareSku}</td>
                                    <td>{item.cluster}</td>
                                    <td>{item.deploymentType}</td>
                                    <td>{item.region}</td>
                                    <td>{item.datacenter}</td>
                                    <td>
                                        <Button class="edit-btn" variant="link" className="InputButton"
                                            onClick={() => {
                                                setEditData(item);
                                                setShowViewModal(true);
                                            }}>
                                            Edit
                                        </Button>
                                    </td>
                                </tr>
                            ))
                        }
                        {(error === null) &&
                            (data.length === 0) &&
                            <tr>
                                <td colspan="10" align="center">
                                    No blocklist is currently scheduled.
                                </td>
                            </tr>
                        }
                    </tbody>
                    {editData && showViewModal && (
                        <ViewBlockListItem
                            show={showViewModal}
                            item={editData}
                            onhide={() => setShowViewModal(false)}
                        />)
                    }
                </Table>
            </div>
        </Container >
    );
};