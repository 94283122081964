import Container from 'react-bootstrap/Container';
import { Navbar, Nav } from 'react-bootstrap';
import '../../css/NavBar.css'
import { PerformAuthAction } from "./SignInOutButton";
import { UtcClock } from './utc-local-clock';
import { AuthenticatedTemplate } from "@azure/msal-react";

const NavBar = () => {

    return (
    <>
      <Navbar bg="primary" variant="dark">
        <Container fluid>
          <Navbar.Brand href="/">Change Management Tool</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <AuthenticatedTemplate>
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                <Nav.Link href="#/conflictGraph">
                  Conflict Graph Generator
                </Nav.Link>
                <Nav.Link href="#/blocklist">
                  Manage Networking Blocklist
                </Nav.Link>
                <Nav.Link href="#/changeRecord">Manage Change Records</Nav.Link>
              </Nav>
              <Nav>
                <Nav.Link
                  href="https://msazure.visualstudio.com/AzureWiki/_wiki/wikis/AzureWiki.wiki/52466/Physical-Network-CAB-Review-Process?anchor=vso-to-fcm"
                  target="_blank"
                >
                  Help
                </Nav.Link>
                <PerformAuthAction actionType="logout" />
              </Nav>
              <Nav>
                <UtcClock />
              </Nav>
            </Navbar.Collapse>
          </AuthenticatedTemplate>
        </Container>
      </Navbar>
      <br />
    </>
  );
};

export default NavBar;
