import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Link } from '@mui/material';
import { ArrowDropDown, CheckCircle, HourglassEmpty, Error } from '@mui/icons-material';

const ValidationResult = ({ validationResult, setValidationResult, changeId }) => {
    const setCheck = (response) => {
        const length = Array.isArray(response) ? response.length : Object.keys(response).length;
        return length > 1 ? "checks" : "check";
    }
    return (
        <div>
            {validationResult && validationResult.passedChecks?.length > 0 && (
                <Accordion>
                    <AccordionSummary expandIcon={<ArrowDropDown color="primary" />}>
                        <Typography>
                            {validationResult.rejectedChecks?.length === 0
                                ? <><CheckCircle style={{ color: 'green' }} /> All checks passed</>
                                : `${validationResult.passedChecks.length} ${setCheck(validationResult.passedChecks)} succeeded`}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            {validationResult.passedChecks.join(",\n")}
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            )}
            {validationResult && validationResult.rejectedChecks?.length > 0 && (
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ArrowDropDown color="primary" />}>
                        <Typography><Error style={{ color: 'red' }} />{`${validationResult.rejectedChecks.length}`} {setCheck(validationResult.rejectedChecks)} failed</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                    {validationResult.rejectedChecks.map((dict) => {
                        const link = dict.CheckName.includes("MaintenanceConflicts") ? `${dict.data +'/' + changeId}` : dict.data;
                        return (
                            <Typography key={dict.CheckName}>
                                {`${dict.CheckName}: `}
                                <Link href={link} target="_blank" rel="noopener noreferrer">
                                    {`${dict.ResponseMessage}`}
                                </Link>
                            </Typography>
                        );
                    })}
                    </AccordionDetails>
                </Accordion>
            )}
            {validationResult && validationResult.manualReviewReason && (
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ArrowDropDown color="primary" />}>
                        <Typography><HourglassEmpty style={{ color: 'orange' }} />Manual Review Reason</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            {validationResult.manualReviewReason}
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            )}
        </div>
    );
}

export default ValidationResult;