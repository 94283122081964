import React from 'react';
import { Form } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import Constants from '../Constants';

export const DevicePresentInMop = ({ isMopRequired, maintenanceType, selectedMop, setSelectedMop }) => {
    const [dcsDeviceList, setDcsDeviceList] = useState("");
    useEffect(() => {
        if (isMopRequired) {
            setDcsDeviceList(selectedMop?.devices?.join('\n') || "");
        }
    }, [isMopRequired, selectedMop?.devices]);
    if (!isMopRequired) {
        const validateDeviceList = (e) => {
            // space, comma, newline separated list of devices
            let devices = e.target.value.split(/[\n, ]/).map(device => device.trim().replace(/^\s+|\s+$/g, ''));
            setDcsDeviceList(e.target.value);
            // remove any empty strings from devices/span
            devices = devices.filter(device => device !== '');
            if (Constants.FiberMaintenanceTypes.includes(maintenanceType)) {
                setSelectedMop(prevData => ({ ...prevData, spans: devices }));
            } else {
              setSelectedMop(prevData => ({ ...prevData, devices: devices }));
            }
        }
        // onBlur format the devices list to a comma separated list
        const formatDeviceList = (e) => {
            let devices = e.target.value.split(/[\n, ]/).map(device => device.trim().replace(/^\s+|\s+$/g, ''));
            devices = devices.filter(device => device !== '');
            e.target.value = devices.join(', ');
        }
        return (
          <Form.Group className="mb-3">
            <Form.Label>
              {Constants.FiberMaintenanceTypes.includes(maintenanceType)?
              "Spans" : "Device Present In Maintenance"}
              <span className="text-danger">*</span>
            </Form.Label>
            <p className="description-text">
              Separate with a comma or a new line
            </p>
            <Form.Control
              name={Constants.FiberMaintenanceTypes.includes(maintenanceType)?
                "Spans" : "Device Present In Maintenance"}
              as="textarea"
              className="cr-textarea"
              onChange={validateDeviceList}
              onBlur={formatDeviceList}
              value={dcsDeviceList}
              required
            />
          </Form.Group>
        );
    }
    else {
        return (
            <Form.Group className="mb-3">
                <Form.Label>Device present in MOP <span className="text-danger">*</span></Form.Label>
                <Form.Control
                    name="devices"
                    as="textarea"
                    className="cr-textarea"
                    value={dcsDeviceList}
                    disabled={true}
                    required
                />
            </Form.Group>
        );
    }
};